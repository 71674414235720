.container {
  position: relative;
}

.inputWrapper {
  position: relative;
}

.input {
  width: 100%;
  height: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  color: #374151;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  transition: all 150ms ease-in-out;
}

.input::placeholder {
  color: #6b7280;
}

.input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

.selected {
  background-color: #eff6ff;
}

.searchIcon {
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  width: 1rem;
  height: 1rem;
  color: #9ca3af;
}

.clearButton {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.25rem;
  border-radius: 9999px;
  transition: background-color 150ms ease-in-out;
}

.clearButton:hover {
  background-color: #e5e7eb;
}

.dropdown {
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 0.25rem;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid #e5e7eb;
  max-height: 15rem;
  overflow-y: auto;
}

.dropdownItem {
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #f3f4f6;
  transition: background-color 150ms ease-in-out;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.dropdownItem:hover {
  background-color: #f9fafb;
}

.itemName {
  font-size: 0.875rem;
  font-weight: 500;
  color: #111827;
}

.itemId {
  font-size: 0.75rem;
  color: #6b7280;
}